
import { EventBuss } from '@/types/global'
import {
    computed,
    defineComponent,
    Emitter,
    GameRecord,
    inject,
    onBeforeUnmount,
    onMounted,
    ref
} from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    setup() {
        const emitter = inject('emitter') as Emitter
        const redFirstCard = ref<string | undefined>()
        const redSecondCard = ref<string | undefined>()
        const redThirdCard = ref<string | undefined>()
        const redTotal = ref<number | undefined>()
        const blueFirstCard = ref<string | undefined>()
        const blueSecondCard = ref<string | undefined>()
        const blueTHirdCard = ref<string | undefined>()
        const blueTotal = ref<number | undefined>()
        const winner = ref<string | undefined>()

        const { t } = useI18n()

        onMounted(() => {
            emitter.on(EventBuss.PREVIEW_RESULT, handleOnPreview)
        })
        onBeforeUnmount(() => {
            emitter.off(EventBuss.PREVIEW_RESULT, handleOnPreview)
        })

        const handleOnPreview = (_data: GameRecord) => {
            redFirstCard.value = _data.bankerOne
            redSecondCard.value = _data.bankerTwo
            redThirdCard.value = _data.bankerThree

            redTotal.value = _data.bankerCount

            blueFirstCard.value = _data.playerOne
            blueSecondCard.value = _data.playerTwo
            blueTHirdCard.value = _data.playerThree

            blueTotal.value = _data.playerCount

            if (redTotal.value === blueTotal.value) {
                if (_data.gameTypeNo === 'G002') {
                    winner.value = '和'
                } else {
                    if (blueFirstCard.value && redFirstCard.value) {
                        if (blueFirstCard.value === redFirstCard.value) {
                            winner.value = '和'
                        } else {
                            const blueFaceValue = parseInt(
                                blueFirstCard.value.substring(0, 1)
                            )
                            const redFaceValue = parseInt(
                                redFirstCard.value.substring(0, 1)
                            )

                            if (blueFaceValue > redFaceValue) {
                                winner.value = '龙赢'
                            } else {
                                winner.value = '虎赢'
                            }
                        }
                    }
                }
            } else if (redTotal.value > blueTotal.value) {
                winner.value = _data.gameTypeNo === 'G002' ? '庄赢' : '龙赢'
            } else {
                winner.value = _data.gameTypeNo === 'G002' ? '闲赢' : '虎赢'
            }
        }

        const showRed1Card = computed(() => {
            if (redFirstCard.value) return true
            else return false
        })

        const showRed2Card = computed(() => {
            if (redSecondCard.value) return true
            else return false
        })

        const showRed3Card = computed(() => {
            if (redThirdCard.value) return true
            else return false
        })

        const showRedTotal = computed(() => {
            if (redTotal.value || redTotal.value == 0) return true
            else return false
        })

        const showBlue1Card = computed(() => {
            if (blueFirstCard.value) return true
            else return false
        })

        const showBlue2Card = computed(() => {
            if (blueSecondCard.value) return true
            else return false
        })

        const showBlue3Card = computed(() => {
            if (blueTHirdCard.value) return true
            else return false
        })

        const showBlueTotal = computed(() => {
            if (blueTotal.value || blueTotal.value === 0) return true
            else return false
        })

        const showWinner = computed(() => {
            if (winner.value) return true
            else return false
        })

        return {
            showRed1Card,
            showRed2Card,
            showRed3Card,
            showRedTotal,
            showBlue1Card,
            showBlue2Card,
            showBlue3Card,
            showBlueTotal,
            redTotal,
            blueTotal,
            blueFirstCard,
            blueSecondCard,
            blueTHirdCard,
            redFirstCard,
            redSecondCard,
            redThirdCard,
            winner,
            showWinner,
            t
        }
    }
})
