
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
    props: {
        color: {
            type: Number as PropType<number>,
            default: () => 0
        },
        label: {
            type: String as PropType<string>,
            default: () => ''
        },
        hasBluePair: {
            type: Boolean as PropType<boolean>,
            default: () => false
        },
        hasRedpair: {
            type: Boolean as PropType<boolean>,
            default: () => false
        }
    },
    setup(props) {
        const fillColor = computed(() => {
            if (props.color === 1) return '#c70004'
            // red
            else if (props.color === 2) return '#00c40c'
            // green
            else if (props.color === 3) return '#0000fe'
            // blue
            else return '#FFF'
        })
        return {
            fillColor
        }
    }
})
